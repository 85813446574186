import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionFeatures.module.css';
import SearchComponent from './SearchFieldForm.js/SearchFieldForm';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import moment from 'moment';
import { isOriginInUse } from '../../../../util/search';
import { useConfiguration } from '../../../../context/configurationContext';


// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeatures = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;

  const fieldOptions = { fieldComponents };

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  const listingFieldsConfig = config.listing.listingFields;

  const handleSubmit = (values) => {
    const { when, where, task, categories } = values;
    const { currentSearchParams, history } = props;
    const { search = "", selectedPlace = {} } = where || {};
    const { origin = {}, bounds = {} } = selectedPlace || {};
    const originMaybe = isOriginInUse(config) ? { origin } : {};
    const dates = when && moment(when?.date).format("YYYY-MM-DD");
    const result = {};

    if (Array.isArray(task) && task.length) {
      categories.forEach(category => {
        const categoryName = category?.categoryName;
        const subCategories = category?.subCategories?.filter(subCategory => task?.includes(subCategory?.option)).map(subCategory => subCategory?.option);
        if (subCategories.length > 0) {
          result[`pub_${categoryName}`] = subCategories;
        }
      });
    }

    const SearchParams = {
      ...currentSearchParams,
    }

    if (search && bounds) {
      Object.assign(SearchParams, {
        ...originMaybe,
        address: search,
        bounds,
      })
    }

    if (dates) {
      Object.assign(SearchParams, {
        dates: `${dates},${dates}`,
      })
    }

    if(Object.entries(result)){
      Object.assign(SearchParams, result);
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, SearchParams))
  }

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div
          className={sectionId === "hero" ? css.heroWrapper : classNames(defaultClasses.blockContainer, css.featuresMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            rootClassName={css.block}
            ctaButtonClass={defaultClasses.ctaButton}
            blocks={blocks}
            sectionId={sectionId}
            responsiveImageSizes="(max-width: 767px) 100vw, 568px"
            options={options}
          />
          {sectionId === "hero" ?
            <SearchComponent
              onSubmit={handleSubmit}
              listingFieldsConfig={listingFieldsConfig}
            />
            : null}
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeatures.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeatures.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default compose(withRouter)(SectionFeatures);
