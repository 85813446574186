import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFeaturedListings } from '../../../LandingPage/LandingPage.duck';
import css from './SectionCarousel.module.css';
import Slider from "react-slick";
import { createSlug } from '../../../../util/urlHelpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { NamedLink } from '../../../../components';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <svg fill="#000000" width="30px" height="30px" viewBox="0 0 1.95 1.95" enableBackground="new 0 0 52 52"><path d="M0.525 1.639V0.311c0 -0.037 0.049 -0.064 0.083 -0.034l0.795 0.649c0.03 0.022 0.03 0.071 0 0.094L0.607 1.676c-0.034 0.026 -0.083 0.004 -0.083 -0.037" /></svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <svg fill="#000000" width="30px" height="30px" viewBox="0 0 1.95 1.95" enableBackground="new 0 0 52 52" ><path d="M1.425 0.311v1.327c0 0.037 -0.049 0.064 -0.083 0.034L0.547 1.024c-0.03 -0.022 -0.03 -0.071 0 -0.094L1.342 0.274c0.034 -0.026 0.083 -0.004 0.083 0.037" /></svg>
    </div>
  );
}

const ShowFeaturedListings = (props) => {
  const {
    getListings,
  } = props
  const [featuredListings, setFeaturedListings] = useState([])
  const fetchListings = async () => {
    const response = await getListings();
    setFeaturedListings(response)
  }

  useEffect(() => {
    fetchListings();
  }, [])

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    featuredListings?.length ?
      <div className={css.featureListingWrapper}>
        <h2 className={css.featureHeading}>Featured Listings</h2>
        <div className={css.featureGrid}>
          <Slider {...settings}>
            {featuredListings.map((item, index) => {
              const id = item?.id?.uuid
              const description = (item.attributes?.description)
              const price = `${(item?.attributes?.price?.amount) / 100} - per ${item?.attributes?.publicData?.unitType}`;
              const title = item?.attributes?.title;
              const slugTitle = createSlug(title)
              const image = item.image.attributes.variants.default.url;

              return (
                <div key={index} style={{}} className={css.featureCardBox} onClick={(e) => {
                  return history.push(createResourceLocatorString('ListingPage', routeConfiguration, { slug: slugTitle, id: id, variantType: 'carousel' }))
                }
                }>
                  <img src={image} alt={title} className={css.featureImage} />
                  <h5 className={css.featurePrice}>KSH {price}</h5>
                  <h4 className={css.featureTitle}>
                    {title}
                  </h4>
                  <h5 className={css.featureDescription}>
                    {description}
                  </h5>
                </div>
              )
            })}
          </Slider>
          <div className={css.moreListingLink}>
            <NamedLink name="SearchPage">
              Find more providers here
            </NamedLink>
          </div>
        </div>
      </div>
      : null
  )
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  getListings: () => dispatch(getFeaturedListings()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ShowFeaturedListings)